// extracted by mini-css-extract-plugin
export var aboutContainer = "index-module--aboutContainer--jj9W3";
export var aboutImage = "index-module--aboutImage--7H54s";
export var aboutImageContainer = "index-module--aboutImageContainer--eDqLL";
export var blurbTitle = "index-module--blurbTitle--i2uUr";
export var homeContainer = "index-module--homeContainer--FDJ6D";
export var homeOuterContainer = "index-module--homeOuterContainer--Qk2Ok";
export var introContainer = "index-module--introContainer--F5t5p";
export var mainHeader = "index-module--mainHeader--90tx8";
export var outerContainer = "index-module--outerContainer--IE1Ry";
export var pageHeader = "index-module--pageHeader--1nU0b";
export var profilePic = "index-module--profilePic--jKoC0";
export var travelContainer = "index-module--travelContainer--lAYzY";