import * as React from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Blog from "../components/Blog";
import Layout from "../components/Layout";
import * as styles from "./index.module.css";
import { StaticImage } from "gatsby-plugin-image";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  console.log("posts", posts);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <div className={styles.homeOuterContainer}>
        <div className={styles.homeContainer}>
          <div className={styles.introContainer}>
            <h1 className={styles.blurbTitle}>Sophia’s Blog</h1>
            <div>
              <p>Welcome! I write about food and travel on this website.</p>
            </div>

            <div>
              <StaticImage
                className={styles.profilePic}
                formats={["auto", "webp", "avif"]}
                src="../images/sophiaHeadshot.jpg"
                quality={95}
                alt="Profile picture"
              />
            </div>
          </div>

          <div>
            <h2 className={styles.mainHeader}>Recent Posts</h2>
            <Blog posts={posts} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
